import React, { useContext, Fragment, ReactElement, useState } from "react";
import { Box } from "@material-ui/core";
import Joi from "joi";
import omit from "lodash/omit";

import { useMutation } from "@apollo/react-hooks";
import { getNames } from "@learnerbly/i18n-iso-countries";

import { NotificationTypes } from "../../../Notification/Notification";
import AppContext from "../../../App/AppContext";
import EditableTable from "../../../EditableTable";

import { AmazonDirectOrderSettingsInput } from "../../../../config/globalTypes";
import {
  UpdateAccountVariables as TUpdateAccountVariables,
  UpdateAccount as TUpdateAccount
} from "../../../_shared/__generated__/UpdateAccount";

import updateAccountMutation from "../../../_shared/update-account.graphql";

interface Row extends AmazonDirectOrderSettingsInput {}

interface TableState {
  columns: any;
  data: Row[];
}

type Props = {
  accountId: string;
  amazonBusinessAccounts?: Row[];
};

const validateRow = (row: AmazonDirectOrderSettingsInput) => {
  const schema = Joi.object<AmazonDirectOrderSettingsInput>({
    adminEmail: Joi.string().required(),
    country: Joi.string().required(),
    username: Joi.string().required(),
    secret: Joi.string().required(),
    groupName: Joi.string().required(),
    purchaseUrl: Joi.string().required()
  });

  return schema.validate(row, {
    abortEarly: false
  });
};

const AmazonBusinessAccounts = ({
  accountId,
  amazonBusinessAccounts
}: Props): ReactElement => {
  const { handleShowNotification } = useContext(AppContext);

  const countries = getNames("en");
  const countrySelectorItems = Object.keys(countries).reduce(
    (acc, country) => ({ ...acc, [country]: countries[country] }),
    {}
  );

  const [state, setState] = useState<TableState>({
    columns: [
      {
        title: "Country",
        field: "country",
        lookup: countrySelectorItems
      },
      { title: "Email", field: "adminEmail" },
      { title: "Username", field: "username" },
      { title: "Secret", field: "secret" },
      { title: "Group Name", field: "groupName" },
      { title: "Purchase URL", field: "purchaseUrl" }
    ],
    data: amazonBusinessAccounts || []
  });

  const [updateAccount] = useMutation<TUpdateAccount, TUpdateAccountVariables>(
    updateAccountMutation
  );

  const cleanMutationPayload = (accounts: Row[]) => {
    const data = accounts.map((account: AmazonDirectOrderSettingsInput) => {
      const result = omit(account, "tableData");
      return Object.entries(result).reduce((acc, [key, value]) => {
        return { ...acc, [key]: value?.trim() };
      }, result);
    });
    return data;
  };

  const onRowAdd = async (newData: Row) => {
    const { error, value } = validateRow(newData);

    if (error) {
      const errors = error.details.map(({ message }) => message);

      handleShowNotification({
        type: NotificationTypes.error,
        message: `Could not add row because ${errors}`
      });

      return;
    }

    const countryExists = state.data.find(
      ({ country }) => country === value.country
    );

    if (countryExists) {
      handleShowNotification({
        type: NotificationTypes.error,
        message: `Could not add row because an account already exists for country ${value.country}.`
      });

      return;
    }

    const updatedAccounts = [...state.data, value];

    await updateAccount({
      variables: {
        id: accountId,
        input: {
          amazonBusinessAccounts: cleanMutationPayload(updatedAccounts)
        }
      }
    });

    return setState(prevState => ({ ...prevState, data: updatedAccounts }));
  };

  const onRowUpdate = async (newData: any, oldData: any) => {
    if (oldData) {
      const { error, value } = validateRow(newData);

      if (error) {
        const errors = error.details.map(({ message }) => message);

        handleShowNotification({
          type: NotificationTypes.error,
          message: `Could not update row because ${errors}`
        });

        return;
      }

      const updatedAccounts = [...state.data];

      updatedAccounts[updatedAccounts.indexOf(oldData)] = value;

      await updateAccount({
        variables: {
          id: accountId,
          input: {
            amazonBusinessAccounts: cleanMutationPayload(updatedAccounts)
          }
        }
      });

      return setState(prevState => ({ ...prevState, data: updatedAccounts }));
    }
  };

  const onRowDelete = async (oldData: any) => {
    const updatedAccounts = [...state.data];

    updatedAccounts.splice(updatedAccounts.indexOf(oldData), 1);

    await updateAccount({
      variables: {
        id: accountId,
        input: {
          amazonBusinessAccounts: cleanMutationPayload(updatedAccounts)
        }
      }
    });

    return setState(prevState => ({ ...prevState, data: updatedAccounts }));
  };

  return (
    <Fragment>
      <Box mb={4}>
        <EditableTable
          title="Amazon Business Accounts"
          tableState={state}
          onRowAdd={onRowAdd}
          onRowUpdate={onRowUpdate}
          onRowDelete={onRowDelete}
        />
      </Box>
    </Fragment>
  );
};

export default AmazonBusinessAccounts;
